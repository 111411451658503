import { CommonMargin } from 'components/_layout/CommonMargin';
import { Section } from 'components/_layout/Section/Section';
import SEO from 'components/_layout/Seo';
import * as Common from 'components/_universal/Common';
import React from 'react';
import { OfferWithDescription, RelativeSection } from 'sections/Offer/Offer.styled';
import { softwareData } from 'sections/Offer/offerData';
import { TakeThisStepSection } from 'sections/_universal/TakeThisStepSection';
import { TrustedBySection } from 'sections/_universal/TrustedBySection';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { OurOfferSection } from 'sections/_universal/OurOfferSection';
import styled from 'styled-components';

export default () => {
  const isMd = useBreakpoint('md');

  return (
    <>
      <SEO
        title="Software development services: custom solution, products, consulting"
        meta={[
          {
            name: 'keywords',
            content:
              'software services, software development services, software consulting services, custom software services, software product development services, software development consulting, custom software development services',
          },
        ]}
        description={
          'Our software development services focus on building custom solutions that align with your business objectives, ensuring functionality and scalability.'
        }
      />

      <CommonMargin>
        <StyledSection title="Software" variant="secondary" id="Software" isMobile={isMd} withBreadCrumbs>
          <HeaderSection>
            Enable digital change and build robust solutions to strengthen your market positioning, delight existing
            users, and get more profits. Create people-centric apps & systems with Us. We help our clients develop
            cutting-edge web & mobile solutions.
          </HeaderSection>
        </StyledSection>
        <Section>
          <Common.Div flex={'column'} gap={isMd ? '0' : '140px'}>
            {softwareData.map(({ imgSrc, title, to, desc, descSecondary, href }, index) => (
              <OfferWithDescription
                alignLeft={isMd || index % 2 === 0}
                imgSrc={imgSrc}
                title={title}
                desc={desc}
                href={href}
                descSecondary={descSecondary}
                isMd={isMd}
                key={title}
                to={to}
              />
            ))}
          </Common.Div>
        </Section>

        <OurOfferSection />
        <TakeThisStepSection />
        <TrustedBySection />
      </CommonMargin>
    </>
  );
};

const HeaderSection = styled.div`
  margin-top: 40px;
  max-width: 900px;
  font-size: 20px;
  line-height: 1.65;
  font-weight: 300;
`;

const StyledSection = styled(RelativeSection)`
  margin-top: 24px;
`;
